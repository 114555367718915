/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { 
  RiArrowLeftSLine, 
  RiCheckboxCircleLine,
  RiCloseCircleLine,
  RiStarFill,
  RiStarLine,
  RiInformationLine,
  RiPriceTag3Line,
  RiUserSmileLine,
  RiComputerLine,
  RiCustomerService2Line
} from "react-icons/ri"

const HRISPage = () => {
  // HRIS comparison data
  const hrisComparison = [
    {
      name: "Workday HCM",
      website: "https://www.workday.com/hcm",
      priceRange: "$$$$",
      pricingModel: "Per employee/year (Enterprise)",
      userRating: 4.3,
      userReviews: 1350,
      usability: 4.1,
      mobileFriendly: true,
      cloudBased: true,
      companySize: "Mid-size to Enterprise",
      keyFeatures: [
        "Unified HR, talent, and payroll",
        "Advanced workforce analytics",
        "Global compliance management",
        "Robust configurability",
        "Powerful reporting engine"
      ],
      limitations: [
        "Complex implementation process",
        "High cost for smaller organizations",
        "Steep learning curve for administrators",
        "Customization requires specialists"
      ],
      bestFor: "Large enterprises with complex HR needs and global operations"
    },
    {
      name: "BambooHR",
      website: "https://appwiki.nl/link/brand/1OIOrEAw4ngBccSnuIoaAmJ9r5VK5anS",
      priceRange: "$$",
      pricingModel: "Per employee/month",
      userRating: 4.6,
      userReviews: 1700,
      usability: 4.8,
      mobileFriendly: true,
      cloudBased: true,
      companySize: "Small to Mid-size",
      keyFeatures: [
        "Intuitive user interface",
        "Employee self-service portal",
        "Streamlined onboarding",
        "Time-off management",
        "Performance management"
      ],
      limitations: [
        "Limited payroll functionality",
        "Basic reporting capabilities",
        "Less suitable for enterprise needs",
        "Limited global compliance features"
      ],
      bestFor: "Small to medium businesses seeking easy-to-use core HR functions"
    },
    {
      name: "ADP Workforce Now",
      website: "https://www.adp.com/workforce-now",
      priceRange: "$$$",
      pricingModel: "Per employee/month + implementation",
      userRating: 4.2,
      userReviews: 1900,
      usability: 3.9,
      mobileFriendly: true,
      cloudBased: true,
      companySize: "Mid-size to Enterprise",
      keyFeatures: [
        "Comprehensive payroll processing",
        "Tax compliance management",
        "Benefits administration",
        "Talent management suite",
        "Robust compliance tools"
      ],
      limitations: [
        "Interface can feel dated",
        "Implementation can be complex",
        "Reporting system has learning curve",
        "Customer support inconsistency"
      ],
      bestFor: "Mid-sized companies with complex payroll needs and compliance requirements"
    },
    {
      name: "UKG Pro (Ultimate)",
      website: "https://www.ukg.com/products/ukg-pro",
      priceRange: "$$$",
      pricingModel: "Per employee/month (annual contract)",
      userRating: 4.4,
      userReviews: 1250,
      usability: 4.3,
      mobileFriendly: true,
      cloudBased: true,
      companySize: "Mid-size to Enterprise",
      keyFeatures: [
        "End-to-end HR, payroll, and talent",
        "Advanced benefits administration",
        "Powerful predictive analytics",
        "Employee sentiment analysis",
        "Detailed compensation planning"
      ],
      limitations: [
        "Higher price point",
        "Complex implementation",
        "Requires dedicated HR staff",
        "Some modules less intuitive than others"
      ],
      bestFor: "Growing mid-sized companies to large organizations requiring comprehensive HR solutions"
    },
    {
      name: "Gusto",
      website: "https://gusto.com",
      priceRange: "$$",
      pricingModel: "Per employee/month + base fee",
      userRating: 4.7,
      userReviews: 1600,
      usability: 4.9,
      mobileFriendly: true,
      cloudBased: true,
      companySize: "Small businesses",
      keyFeatures: [
        "Simple payroll processing",
        "Automated tax filings",
        "Employee benefits management",
        "Time tracking integration",
        "Intuitive onboarding workflows"
      ],
      limitations: [
        "Limited enterprise features",
        "Basic reporting capabilities",
        "Less suitable for international businesses",
        "Limited custom workflow options"
      ],
      bestFor: "Small businesses looking for simplified payroll and HR management"
    },
    {
      name: "Rippling",
      website: "https://appwiki.nl/link/brand/3Em4dpgtmE62CejwOnih5jwZomxJIZHw",
      priceRange: "$$",
      pricingModel: "Per employee/month + module pricing",
      userRating: 4.8,
      userReviews: 950,
      usability: 4.7,
      mobileFriendly: true,
      cloudBased: true,
      companySize: "Small to Mid-size",
      keyFeatures: [
        "Unified HR and IT management",
        "Fast onboarding/offboarding",
        "App, device, and identity management",
        "Global payroll capabilities",
        "Extensive integration ecosystem"
      ],
      limitations: [
        "Newer platform with evolving features",
        "Modular pricing can increase costs",
        "Some advanced features require add-ons",
        "Less established than legacy systems"
      ],
      bestFor: "Tech-forward companies seeking unified HR and IT management"
    },
    {
      name: "Paylocity",
      website: "https://www.paylocity.com",
      priceRange: "$$$",
      pricingModel: "Per employee/month (tiered)",
      userRating: 4.4,
      userReviews: 1150,
      usability: 4.2,
      mobileFriendly: true,
      cloudBased: true,
      companySize: "Small to Mid-size",
      keyFeatures: [
        "Modern user interface",
        "Strong payroll compliance",
        "Learning management system",
        "Employee engagement tools",
        "Community/social collaboration features"
      ],
      limitations: [
        "Implementation can be challenging",
        "Mid-tier pricing point",
        "Some modules less robust than competitors",
        "Customer service inconsistency"
      ],
      bestFor: "Mid-sized companies wanting modern HR features with strong payroll capabilities"
    },
    {
      name: "SAP SuccessFactors",
      website: "https://www.sap.com/successfactors",
      priceRange: "$$$$",
      pricingModel: "Per user/month (annual enterprise contracts)",
      userRating: 4.1,
      userReviews: 1050,
      usability: 3.8,
      mobileFriendly: true,
      cloudBased: true,
      companySize: "Enterprise",
      keyFeatures: [
        "Comprehensive talent management",
        "Core HR and payroll",
        "Robust learning management",
        "Advanced analytics and reporting",
        "Global compliance capabilities"
      ],
      limitations: [
        "Complex implementation cycle",
        "Significant consulting costs",
        "Interface less intuitive than newer systems",
        "Requires dedicated administration team"
      ],
      bestFor: "Large global enterprises requiring deep talent management capabilities"
    },
    {
      name: "Zenefits",
      website: "https://www.zenefits.com",
      priceRange: "$$",
      pricingModel: "Per employee/month (tiered plans)",
      userRating: 4.2,
      userReviews: 1050,
      usability: 4.5,
      mobileFriendly: true,
      cloudBased: true,
      companySize: "Small to Mid-size",
      keyFeatures: [
        "Streamlined benefits administration",
        "Integrated payroll processing",
        "Compliance dashboard",
        "Time and attendance tracking",
        "Streamlined employee onboarding"
      ],
      limitations: [
        "Customer support challenges",
        "Less suitable for complex enterprises",
        "Basic performance management",
        "Limited global capabilities"
      ],
      bestFor: "Small to mid-sized businesses focusing on benefits administration and compliance"
    },
    {
      name: "Paycor",
      website: "https://www.paycor.com",
      priceRange: "$$",
      pricingModel: "Per employee/month + implementation",
      userRating: 4.3,
      userReviews: 1100,
      usability: 4.2,
      mobileFriendly: true,
      cloudBased: true,
      companySize: "Small to Mid-size",
      keyFeatures: [
        "Strong payroll capabilities",
        "Built-in tax compliance",
        "Recruiting and onboarding",
        "Learning management system",
        "Mobile employee self-service"
      ],
      limitations: [
        "Implementation can be lengthy",
        "Add-on modules increase costs",
        "Reporting could be more intuitive",
        "Limited global features"
      ],
      bestFor: "US-based small to mid-sized businesses needing strong payroll and compliance features"
    }
  ];

  // Helper function to render star ratings
  const renderStars = (rating) => {
    const stars = [];
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 >= 0.3;
    
    for (let i = 1; i <= 5; i++) {
      if (i <= fullStars) {
        stars.push(<RiStarFill key={i} sx={{ color: 'primary' }} />);
      } else if (i === fullStars + 1 && hasHalfStar) {
        stars.push(<RiStarFill key={i} sx={{ color: 'primary', opacity: 0.5 }} />);
      } else {
        stars.push(<RiStarLine key={i} sx={{ color: 'primary' }} />);
      }
    }
    
    return <div sx={{ display: 'flex', alignItems: 'center' }}>{stars} <span sx={{ ml: 1 }}>({rating}/5)</span></div>;
  };

  return (
    <Layout>
      <SEO 
        title="Top 10 HRIS Systems Compared | HR Software Guide"
        description="Compare the top 10 Human Resource Information Systems (HRIS) for 2025. Detailed analysis of pricing, features, and user feedback to help you choose the best HRIS."
        keywords={[
          "HRIS comparison",
          "best HRIS software",
          "human resource information systems",
          "HR management systems",
          "HRIS pricing comparison",
          "HRIS user reviews",
          "payroll software"
        ]}
      />
      
      <div sx={{
        bg: 'primaryMuted',
        pt: [4, 5],
        pb: [4, 5]
      }}>
        <div sx={{
          maxWidth: '1200px',
          mx: 'auto',
          px: 3
        }}>
          <div sx={{
            display: 'flex',
            alignItems: 'center',
            mb: 4
          }}>
            <Link to="/software" sx={{
              display: 'flex',
              alignItems: 'center',
              color: 'primary',
              textDecoration: 'none',
              fontSize: '1rem',
              fontWeight: 500
            }}>
              <RiArrowLeftSLine sx={{ mr: 1 }} /> Back to HR Software
            </Link>
          </div>
          
          <div sx={{
            textAlign: 'center',
            mb: 2,
            fontSize: '0.95rem',
            color: 'text',
            opacity: 0.8
          }}>
            Last Updated: March 2025
          </div>
          
          <h1 sx={{
            fontSize: ['1.8rem', '2.3rem'],
            fontWeight: 700,
            textAlign: 'center',
            mb: 3,
            color: 'primary'
          }}>
            Top 10 HRIS Systems Compared
          </h1>
          
          <p sx={{
            fontSize: '1.1rem',
            textAlign: 'center',
            maxWidth: '800px',
            mx: 'auto',
            mb: 5,
            lineHeight: 1.5,
            color: 'text'
          }}>
            An in-depth comparison of the leading Human Resource Information Systems to help you find the right
            HRIS solution for your organization's size, budget, and specific needs.
          </p>
          
          {/* Comparison criteria explanation */}
          <div sx={{
            bg: 'white',
            p: 3,
            borderRadius: '8px',
            boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
            mb: 5
          }}>
            <h2 sx={{
              fontSize: '1.3rem',
              fontWeight: 600,
              mb: 3,
              display: 'flex',
              alignItems: 'center'
            }}>
              <RiInformationLine sx={{ mr: 2, color: 'primary' }} /> Comparison Criteria
            </h2>
            
            <div sx={{
              display: 'grid',
              gridTemplateColumns: ['1fr', '1fr 1fr', '1fr 1fr 1fr 1fr'],
              gap: 3,
              mb: 3
            }}>
              <div>
                <h3 sx={{ fontSize: '1rem', fontWeight: 600, mb: 1, display: 'flex', alignItems: 'center' }}>
                  <RiPriceTag3Line sx={{ mr: 1, color: 'primary' }} /> Pricing
                </h3>
                <p sx={{ fontSize: '0.9rem', opacity: 0.8 }}>
                  $ = Budget (Under $10/employee/mo)<br />
                  $$ = Mid-range ($10-20/employee/mo)<br />
                  $$$ = Premium ($20-35/employee/mo)<br />
                  $$$$ = Enterprise (Custom pricing)
                </p>
              </div>
              
              <div>
                <h3 sx={{ fontSize: '1rem', fontWeight: 600, mb: 1, display: 'flex', alignItems: 'center' }}>
                  <RiUserSmileLine sx={{ mr: 1, color: 'primary' }} /> User Rating
                </h3>
                <p sx={{ fontSize: '0.9rem', opacity: 0.8 }}>
                  Based on verified reviews across G2, Capterra, and SoftwareAdvice platforms. Rating out of 5.
                </p>
              </div>
              
              <div>
                <h3 sx={{ fontSize: '1rem', fontWeight: 600, mb: 1, display: 'flex', alignItems: 'center' }}>
                  <RiComputerLine sx={{ mr: 1, color: 'primary' }} /> Usability
                </h3>
                <p sx={{ fontSize: '0.9rem', opacity: 0.8 }}>
                  Ease of use rating based on user interface design, implementation complexity, and administrative burden.
                </p>
              </div>
              
              <div>
                <h3 sx={{ fontSize: '1rem', fontWeight: 600, mb: 1, display: 'flex', alignItems: 'center' }}>
                  <RiCustomerService2Line sx={{ mr: 1, color: 'primary' }} /> Company Size
                </h3>
                <p sx={{ fontSize: '0.9rem', opacity: 0.8 }}>
                  The ideal organization size where the solution performs best based on features, scalability, and pricing.
                </p>
              </div>
            </div>
          </div>
          
          {/* HRIS comparison cards */}
          <div sx={{
            display: 'grid',
            gridTemplateColumns: ['1fr', '1fr', '1fr 1fr'],
            gap: 4,
            mb: 5
          }}>
            {hrisComparison.map((hris, index) => (
              <div key={hris.name} sx={{
                bg: 'white',
                p: [3, 4],
                borderRadius: '8px',
                boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
                transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                display: 'flex',
                flexDirection: 'column',
                '&:hover': {
                  transform: 'translateY(-3px)',
                  boxShadow: '0 6px 16px rgba(0,0,0,0.08)'
                }
              }}>
                <div sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  mb: 3
                }}>
                  <h3 sx={{
                    fontSize: '1.4rem',
                    fontWeight: 700,
                    color: 'primary'
                  }}>
                    {index + 1}. {hris.name}
                  </h3>
                  <div sx={{
                    fontSize: '1.1rem',
                    fontWeight: 600,
                    color: 'primary'
                  }}>
                    {hris.priceRange}
                  </div>
                </div>
                
                <div sx={{
                  display: 'grid',
                  gridTemplateColumns: ['1fr', '1fr 1fr'],
                  gap: 3,
                  mb: 3
                }}>
                  <div>
                    <div sx={{ fontSize: '0.9rem', fontWeight: 600, mb: 1 }}>Pricing Model</div>
                    <div sx={{ fontSize: '0.9rem' }}>{hris.pricingModel}</div>
                  </div>
                  
                  <div>
                    <div sx={{ fontSize: '0.9rem', fontWeight: 600, mb: 1 }}>Company Size</div>
                    <div sx={{ fontSize: '0.9rem' }}>{hris.companySize}</div>
                  </div>
                  
                  <div>
                    <div sx={{ fontSize: '0.9rem', fontWeight: 600, mb: 1 }}>User Rating ({hris.userReviews} reviews)</div>
                    <div sx={{ fontSize: '0.9rem' }}>{renderStars(hris.userRating)}</div>
                  </div>
                  
                  <div>
                    <div sx={{ fontSize: '0.9rem', fontWeight: 600, mb: 1 }}>Usability</div>
                    <div sx={{ fontSize: '0.9rem' }}>{renderStars(hris.usability)}</div>
                  </div>
                  
                  <div>
                    <div sx={{ fontSize: '0.9rem', fontWeight: 600, mb: 1 }}>Mobile-Friendly</div>
                    <div sx={{ fontSize: '0.9rem' }}>
                      {hris.mobileFriendly ? 
                        <span sx={{ color: 'green', display: 'flex', alignItems: 'center' }}>
                          <RiCheckboxCircleLine sx={{ mr: 1 }} /> Yes
                        </span> : 
                        <span sx={{ color: 'red', display: 'flex', alignItems: 'center' }}>
                          <RiCloseCircleLine sx={{ mr: 1 }} /> No
                        </span>
                      }
                    </div>
                  </div>
                  
                  <div>
                    <div sx={{ fontSize: '0.9rem', fontWeight: 600, mb: 1 }}>Cloud-Based</div>
                    <div sx={{ fontSize: '0.9rem' }}>
                      {hris.cloudBased ? 
                        <span sx={{ color: 'green', display: 'flex', alignItems: 'center' }}>
                          <RiCheckboxCircleLine sx={{ mr: 1 }} /> Yes
                        </span> : 
                        <span sx={{ color: 'red', display: 'flex', alignItems: 'center' }}>
                          <RiCloseCircleLine sx={{ mr: 1 }} /> No
                        </span>
                      }
                    </div>
                  </div>
                </div>
                
                <div sx={{ mb: 3 }}>
                  <div sx={{ fontSize: '0.9rem', fontWeight: 600, mb: 1 }}>Key Features</div>
                  <ul sx={{ pl: 3, mb: 0 }}>
                    {hris.keyFeatures.map(feature => (
                      <li key={feature} sx={{ fontSize: '0.9rem', mb: 1 }}>{feature}</li>
                    ))}
                  </ul>
                </div>
                
                <div sx={{ mb: 3 }}>
                  <div sx={{ fontSize: '0.9rem', fontWeight: 600, mb: 1 }}>Limitations</div>
                  <ul sx={{ pl: 3, mb: 0 }}>
                    {hris.limitations.map(limitation => (
                      <li key={limitation} sx={{ fontSize: '0.9rem', mb: 1 }}>{limitation}</li>
                    ))}
                  </ul>
                </div>
                
                <div sx={{
                  bg: 'primaryMuted',
                  p: 2,
                  borderRadius: '4px',
                  textAlign: 'center',
                  fontWeight: 600,
                  mb: 3
                }}>
                  Best For: {hris.bestFor}
                </div>
                
                <div sx={{ mt: 'auto' }}>
                  <a href={hris.website} 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    sx={{
                      display: 'block',
                      textAlign: 'center',
                      bg: 'primary',
                      color: 'white',
                      py: 2,
                      px: 3,
                      borderRadius: '4px',
                      textDecoration: 'none',
                      fontWeight: 600,
                      fontSize: '0.95rem',
                      transition: 'transform 0.2s ease, box-shadow 0.2s ease, background-color 0.2s ease',
                      '&:hover': {
                        bg: 'primaryDark',
                        color: 'white',
                        transform: 'translateY(-2px)',
                        boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
                      }
                    }}
                  >
                    Visit Website
                  </a>
                </div>
              </div>
            ))}
          </div>

          {/* HRIS selection guide */}
          <div sx={{
            bg: 'white',
            p: [3, 4],
            borderRadius: '8px',
            boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
            mb: 5
          }}>
            <h2 sx={{ 
              fontSize: '1.4rem',
              fontWeight: 600,
              mb: 3,
              pb: 2,
              borderBottom: '1px solid',
              borderColor: 'primaryMuted'
            }}>
              How to Select the Right HRIS for Your Organization
            </h2>
            
            <div sx={{ mb: 4 }}>
              <h3 sx={{ fontSize: '1.1rem', fontWeight: 600, mb: 2 }}>Determine Core Requirements</h3>
              <p sx={{ fontSize: '0.95rem', lineHeight: 1.7 }}>
                Before evaluating HRIS options, clearly identify your organization's critical requirements. Focus on must-have 
                features versus nice-to-have capabilities. Consider current pain points in your HR processes, compliance needs, 
                integration requirements, and future growth plans. Prioritize systems that excel in your most critical functions, 
                whether that's payroll processing, benefits administration, or employee self-service.
              </p>
            </div>
            
            <div sx={{ mb: 4 }}>
              <h3 sx={{ fontSize: '1.1rem', fontWeight: 600, mb: 2 }}>Assess Implementation Requirements</h3>
              <p sx={{ fontSize: '0.95rem', lineHeight: 1.7 }}>
                HRIS implementation complexity varies significantly between platforms. Consider your available resources, 
                timeline, and expertise when evaluating options. Some systems require months of setup and dedicated IT support, 
                while others can be operational in weeks with minimal technical resources. Factor in data migration complexity, 
                training needs, and customization requirements to ensure a successful implementation.
              </p>
            </div>
            
            <div sx={{ mb: 4 }}>
              <h3 sx={{ fontSize: '1.1rem', fontWeight: 600, mb: 2 }}>Consider Total Cost of Ownership</h3>
              <p sx={{ fontSize: '0.95rem', lineHeight: 1.7 }}>
                Look beyond the subscription price to understand the full financial commitment. Consider implementation 
                costs, ongoing administration expenses, training requirements, and potential customization needs. Also 
                evaluate whether the system will require additional staff or consultants to maintain. The most affordable 
                system initially may end up being more expensive long-term if it requires significant support resources.
              </p>
            </div>
            
            <div sx={{ mb: 4 }}>
              <h3 sx={{ fontSize: '1.1rem', fontWeight: 600, mb: 2 }}>Evaluate Integration Capabilities</h3>
              <p sx={{ fontSize: '0.95rem', lineHeight: 1.7 }}>
                Modern HR departments rely on multiple systems working together seamlessly. Assess how well each HRIS integrates 
                with your existing technology ecosystem, including payroll, benefits administration, time tracking, recruiting, 
                and finance systems. Pre-built integrations reduce implementation time and maintenance burden, while robust APIs 
                provide flexibility for custom connections when needed.
              </p>
            </div>
            
            <div>
              <h3 sx={{ fontSize: '1.1rem', fontWeight: 600, mb: 2 }}>Involve Stakeholders in Evaluation</h3>
              <p sx={{ fontSize: '0.95rem', lineHeight: 1.7 }}>
                The most successful HRIS implementations involve input from various stakeholders. Include representatives from 
                HR, IT, finance, and line managers in the evaluation process. Consider the needs of all system users, from 
                HR administrators to employees using self-service features. Prioritize systems with intuitive interfaces 
                that will encourage adoption and reduce training requirements across the organization.
              </p>
            </div>
          </div>
          
          {/* Trends in HRIS technology */}
          <div sx={{
            bg: 'white',
            p: [3, 4],
            borderRadius: '8px',
            boxShadow: '0 2px 8px rgba(0,0,0,0.05)'
          }}>
            <h2 sx={{ 
              fontSize: '1.4rem',
              fontWeight: 600,
              mb: 3,
              pb: 2,
              borderBottom: '1px solid',
              borderColor: 'primaryMuted'
            }}>
              Current Trends in HRIS Technology
            </h2>
            
            <div sx={{
              display: 'grid',
              gridTemplateColumns: ['1fr', '1fr 1fr'],
              gap: 4,
              mb: 4
            }}>
              <div>
                <h3 sx={{ fontSize: '1.1rem', fontWeight: 600, mb: 2 }}>AI-Powered Analytics</h3>
                <p sx={{ fontSize: '0.95rem', lineHeight: 1.7 }}>
                  Leading HRIS platforms now incorporate AI to transform HR data into actionable insights. These systems can 
                  predict turnover risks, identify engagement patterns, suggest optimal compensation strategies, and highlight 
                  potential compliance issues before they become problems. Advanced analytics help HR professionals move from 
                  reactive to proactive workforce management and demonstrate HR's strategic value to the organization.
                </p>
              </div>
              
              <div>
                <h3 sx={{ fontSize: '1.1rem', fontWeight: 600, mb: 2 }}>Employee Experience Focus</h3>
                <p sx={{ fontSize: '0.95rem', lineHeight: 1.7 }}>
                  Modern HRIS solutions prioritize user experience for all stakeholders, especially employees. Self-service 
                  portals now feature intuitive interfaces, mobile accessibility, and consumer-grade design. Some platforms 
                  include personalized dashboards, AI-assisted Q&A, and guided workflows for common tasks. This focus on 
                  experience improves adoption rates, reduces administrative burden, and supports remote/hybrid work models.
                </p>
              </div>
              
              <div>
                <h3 sx={{ fontSize: '1.1rem', fontWeight: 600, mb: 2 }}>Integration & Unified Platforms</h3>
                <p sx={{ fontSize: '0.95rem', lineHeight: 1.7 }}>
                  The trend toward unified HR platforms continues, with many organizations seeking to consolidate multiple 
                  HR functions into a single system. Vendors have responded by expanding capabilities through acquisitions 
                  and development, while also improving API capabilities for organizations that prefer best-of-breed approaches. 
                  This reduces data silos, improves reporting accuracy, and provides a more consistent experience for all users.
                </p>
              </div>
              
              <div>
                <h3 sx={{ fontSize: '1.1rem', fontWeight: 600, mb: 2 }}>Global Compliance Management</h3>
                <p sx={{ fontSize: '0.95rem', lineHeight: 1.7 }}>
                  As organizations expand globally, HRIS systems have evolved to support complex multi-country compliance 
                  requirements. Advanced platforms now include built-in compliance frameworks for various countries, automated 
                  updates for changing regulations, and configurable workflows to ensure proper approvals. This helps 
                  reduce compliance risks while enabling consistent HR practices across geographic boundaries.
                </p>
              </div>
            </div>

            <div sx={{
              display: 'grid',
              gridTemplateColumns: ['1fr', '1fr 1fr'],
              gap: 4
            }}>
              <div>
                <h3 sx={{ fontSize: '1.1rem', fontWeight: 600, mb: 2 }}>Remote Work Support</h3>
                <p sx={{ fontSize: '0.95rem', lineHeight: 1.7 }}>
                  The widespread adoption of remote and hybrid work models has driven HRIS innovation in several areas. 
                  Modern systems now include features for virtual onboarding, digital document management, remote time tracking, 
                  and tools to monitor employee wellbeing across distributed teams. Some platforms include location tracking 
                  for tax compliance and features to help managers maintain engagement with remote team members.
                </p>
              </div>
              
              <div>
                <h3 sx={{ fontSize: '1.1rem', fontWeight: 600, mb: 2 }}>Continuous Performance Management</h3>
                <p sx={{ fontSize: '0.95rem', lineHeight: 1.7 }}>
                  HRIS systems are increasingly moving away from traditional annual review models toward continuous feedback 
                  and goal management. Modern platforms support regular check-ins, real-time feedback, OKR (Objectives and Key Results) 
                  tracking, and peer recognition. These tools help organizations maintain alignment, improve engagement, and develop 
                  talent more effectively, especially in fast-changing environments and with remote workforces.
                </p>
              </div>
            </div>
          </div>
          
          {/* CTA for other HR software comparisons */}
          <div sx={{ mb: 5 }}>
            <h2 sx={{ fontSize: ['1.4rem', '1.6rem'], fontWeight: 700, mb: 4, textAlign: 'center' }}>
              Explore More HR Software Comparison Guides
            </h2>
            
            <div sx={{ 
              display: 'grid', 
              gridTemplateColumns: ['1fr', null, '1fr 1fr', 'repeat(4, 1fr)'],
              gap: 3,
              maxWidth: '900px',
              mx: 'auto'
            }}>
              <Link to="/software/applicant-tracking-system/" sx={{
                py: 2,
                px: 3,
                bg: 'primary',
                color: 'white',
                borderRadius: '5px',
                textDecoration: 'none',
                fontWeight: 500,
                transition: 'transform 0.2s ease, box-shadow 0.2s ease, background-color 0.2s ease',
                ':hover': {
                  bg: 'primaryDark',
                  color: 'white',
                  transform: 'translateY(-2px)',
                  boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
                }
              }}>
                ATS Comparison
              </Link>
              
              <Link to="/software/performance-management" sx={{
                display: 'block',
                bg: 'white',
                p: 3,
                borderRadius: '10px',
                boxShadow: '0 3px 10px rgba(0,0,0,0.08)',
                textDecoration: 'none',
                color: 'text',
                textAlign: 'center',
                transition: 'transform 0.2s, box-shadow 0.2s',
                ':hover': {
                  transform: 'translateY(-3px)',
                  boxShadow: '0 8px 20px rgba(0,0,0,0.12)'
                }
              }}>
                <div sx={{ 
                  fontSize: '1.1rem', 
                  fontWeight: 600,
                  color: 'primary',
                  mb: 1
                }}>
                  Performance Tools
                </div>
                <div sx={{ fontSize: '0.9rem' }}>
                  Review performance management platforms
                </div>
              </Link>
              
              <Link to="/software/learning-management-system" sx={{
                display: 'block',
                bg: 'white',
                p: 3,
                borderRadius: '10px',
                boxShadow: '0 3px 10px rgba(0,0,0,0.08)',
                textDecoration: 'none',
                color: 'text',
                textAlign: 'center',
                transition: 'transform 0.2s, box-shadow 0.2s',
                ':hover': {
                  transform: 'translateY(-3px)',
                  boxShadow: '0 8px 20px rgba(0,0,0,0.12)'
                }
              }}>
                <div sx={{ 
                  fontSize: '1.1rem', 
                  fontWeight: 600,
                  color: 'primary',
                  mb: 1
                }}>
                  LMS Platforms
                </div>
                <div sx={{ fontSize: '0.9rem' }}>
                  Compare learning management systems
                </div>
              </Link>
              
              <Link to="/software/employee-engagement" sx={{
                display: 'block',
                bg: 'white',
                p: 3,
                borderRadius: '10px',
                boxShadow: '0 3px 10px rgba(0,0,0,0.08)',
                textDecoration: 'none',
                color: 'text',
                textAlign: 'center',
                transition: 'transform 0.2s, box-shadow 0.2s',
                ':hover': {
                  transform: 'translateY(-3px)',
                  boxShadow: '0 8px 20px rgba(0,0,0,0.12)'
                }
              }}>
                <div sx={{ 
                  fontSize: '1.1rem', 
                  fontWeight: 600,
                  color: 'primary',
                  mb: 1
                }}>
                  Engagement Platforms
                </div>
                <div sx={{ fontSize: '0.9rem' }}>
                  Discover employee engagement solutions
                </div>
              </Link>
            </div>
          </div>
          
          {/* Get Expert Help CTA */}
          <div sx={{
            bg: 'primary',
            borderRadius: '12px',
            p: 4,
            textAlign: 'center',
            maxWidth: '800px',
            mx: 'auto',
            mb: 6
          }}>
            <h2 sx={{ 
              fontSize: ['1.5rem', '1.8rem'], 
              fontWeight: 700, 
              color: 'white',
              mb: 3 
            }}>
              Need Help Selecting the Right HRIS Software?
            </h2>
            <p sx={{ 
              fontSize: ['1rem', '1.1rem'], 
              color: 'white',
              mb: 4,
              opacity: 0.9
            }}>
              Our HR technology experts can provide personalized recommendations based on your organization's 
              size, industry, and specific HR needs.
            </p>
            <div sx={{
              display: 'flex',
              flexDirection: ['column', 'row'],
              justifyContent: 'center',
              gap: 3
            }}>
              <Link to="/contact" sx={{
                bg: 'white',
                color: 'primary',
                py: 2,
                px: 4,
                borderRadius: '40px',
                fontWeight: 600,
                textDecoration: 'none',
                fontSize: '1rem',
                display: 'inline-block',
                transition: 'transform 0.2s, box-shadow 0.2s',
                ':hover': {
                  transform: 'translateY(-3px)',
                  boxShadow: '0 6px 20px rgba(0,0,0,0.2)',
                  color: 'primary'
                }
              }}>
                Schedule a Free Consultation
              </Link>
              <Link to="/services/hr-software-selection" sx={{
                bg: 'transparent',
                color: 'white',
                py: 2,
                px: 4,
                borderRadius: '40px',
                fontWeight: 600,
                textDecoration: 'none',
                fontSize: '1rem',
                display: 'inline-block',
                border: '2px solid white',
                transition: 'transform 0.2s, box-shadow 0.2s, background-color 0.2s',
                ':hover': {
                  transform: 'translateY(-3px)',
                  boxShadow: '0 6px 20px rgba(0,0,0,0.2)',
                  bg: 'primaryDark',
                  color: 'white'
                }
              }}>
                Learn About Our Selection Process
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default HRISPage; 